// These 2 events should capture any actual search request to Coveo that gets fulfilled.

import * as Sentry from '@sentry/nextjs';
import { CoveoSearchHub } from './coveoConstants';
import { getSavedSearchToken } from './searchTokenHelpers';

// fetchPage is used for pagination and changing results per page. Otherwise executeSearch triggers for any other search action (changing query, facets, category, sort, etc)
enum SearchActions {
  EXECUTE_SEARCH_FULFILLED = 'search/executeSearch/fulfilled',
  SEARCH_FETCH_PAGE = 'search/fetchPage/fulfilled',
  EXECUTE_SEARCH_REJECTED = 'search/executeSearch/rejected',
  SEARCH_FETCH_PAGE_REJECTED = 'search/fetchPage/rejected',
  DID_YOU_MEAN_CORRECTION = 'didYouMean/correction',
}

/**
 * Redux middleware we inject into the coveo headless engine. This will run when any coveo redux action is dispatched.
 * We can use this to intercept the search actions and send it to our own endpoint for tracking.
 */
export const interceptSearchMiddleware = (_) => (next) => (action) => {
  if (Object.values(SearchActions).includes(action.type)) {
    const savedSearchToken = getSavedSearchToken(CoveoSearchHub.SEARCH);
    const isAbortedError =
      action.type.includes('rejected') &&
      action.error?.message.includes('signal is aborted without reason');

    const payload = action.payload;

    if (!isAbortedError) {
      hashString(savedSearchToken ?? '').then((hashedSearchToken) => {
        let body = {
          // hash the search token and use it as a way for us to track requests by user session
          hashedSearchToken,
          advancedExpression: payload?.response?.advancedExpression ?? '',
          query: payload?.queryExecuted ?? '',
          queryPipeline: payload?.response?.pipeline ?? '',
          // get entire pathname and hash and query params to send
          url: window.location.href ?? '',
          isError: action.type.includes('rejected'),
        };

        if (action.type === SearchActions.DID_YOU_MEAN_CORRECTION) {
          // didYouMeanCorrection payload is only the corrected query
          // hardcode some information in the body so we can track what kind of event, and since we know it will only be triggered from the Search Pipeline
          body = {
            ...body,
            advancedExpression: `didYouMeanCorrection`,
            query: payload,
            queryPipeline: 'Assetstore_Website_Search',
          };
        }

        navigator.sendBeacon('/api/coveo/pubsub', JSON.stringify(body));
      });
    }
  }

  return next(action);
};

async function hashString(input: string) {
  try {
    // Convert the input string to a Uint8Array
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    // Hash the data using SHA-256
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert the hash buffer to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');

    return hashHex;
  } catch (error) {
    Sentry.captureException(error);
    return 'unableToHashString';
  }
}
