import { ExperimentUser } from '@amplitude/experiment-js-client';

export class AmplitudeAnalyticsClient {
  private readonly user: ExperimentUser;
  private readonly amplitudeAnalyticsAPIKey: string;
  private readonly analyticsApiUrl = 'https://api2.amplitude.com/2/httpapi';

  constructor(user: ExperimentUser) {
    this.user = user;
    this.amplitudeAnalyticsAPIKey =
      process.env.NEXT_PUBLIC_AMPLITUDE_ANALYTICS_API_KEY || '';
  }

  exposureEvent = async (experimentKey: string, experimentValue: string) => {
    if (!this.amplitudeAnalyticsAPIKey) {
      return;
    }

    fetch(`${this.analyticsApiUrl}`, {
      method: 'POST',
      body: JSON.stringify({
        api_key: this.amplitudeAnalyticsAPIKey,
        events: [
          {
            event_type: '$exposure',
            user_id: this.user.user_id,
            device_id: this.user.device_id,
            event_properties: {
              flag_key: experimentKey,
              variant: experimentValue,
            },
          },
        ],
      }),
    });
  };
}
