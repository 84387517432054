'use client';

import { ServerTimeContext } from '@contexts/ServerTimeContext';
import { Duration, intervalToDuration } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { getAdjustedClientTime } from 'utils';

const ONE_SECOND_IN_MILLISECONDS = 1000;

/**
 * Counts down every second and returns the duration of time until the targetDate.
 * @param targetDate
 * @returns Duration of time until the targetDate. See date-fns Duration for information on usage and formatting.
 */
export const useCountdown = (
  targetDate?: Date,
  countdownIncrement: number = ONE_SECOND_IN_MILLISECONDS
) => {
  const { serverTimeOffsetInMs } = useContext(ServerTimeContext);
  const [countDownDuration, setCountDownDuration] = useState<Duration | null>(
    targetDate
      ? intervalToDuration({
          start: getAdjustedClientTime(new Date(), serverTimeOffsetInMs),
          end: targetDate,
        })
      : null
  );

  useEffect(() => {
    const interval = targetDate
      ? setInterval(() => {
          setCountDownDuration(
            intervalToDuration({
              start: getAdjustedClientTime(new Date(), serverTimeOffsetInMs),
              end: targetDate,
            })
          );
        }, countdownIncrement)
      : null;

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [targetDate, countdownIncrement, serverTimeOffsetInMs]);

  return countDownDuration;
};
