import { Maybe } from '@lib/types/Maybe';

/**
 * Generic type guard to check if a value is defined or not
 *
 * @param value - value that is potentially not defined
 * @returns true if value is not null or undefined
 */
export function isDefined<TValue>(value: Maybe<TValue>): value is TValue {
  return value !== undefined && value !== null;
}
