/**
 * In the cookie consent banner, we manage the categories: functional (C0003) and targeting (C0004).
 * Whenever the user enables or disables a category, a boolean value following the format (Category ID: State) is updated.
 * For further details on Category IDs, refer to:
 * https://developer.onetrust.com/onetrust/docs/when-consent-changes-ios#category-ids
 */
export const TARGETING_CATEGORY_ENABLED = 'C0004:1';
export const PERFORMANCE_CATEGORY_ENABLED = 'C0002:1';
export const FUNCTIONAL_CATEGORY_ENABLED = 'C0003:1';

// The'OptanonConsent' cookie value is used for OneTrust consent management.
export const OPT_OUT_COOKIE_NAME = 'OptanonConsent';
