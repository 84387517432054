/**
 * Creates a debounced function that delays invoking the provided function until after `ms` milliseconds have elapsed since the last time the debounced function was invoked.
 * Ref: https://decipher.dev/30-seconds-of-typescript/docs/debounce/
 */
export const debounce = (callback: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => callback.apply(this, args), ms);
  };
};
