/**
 * This file contains a list of environment variables that can be used in the application,
 * and a helper function for using them.
 * Any environment variables that are added to the .env files
 * or injected in runtime through terraform should be added here.
 */

export enum EnvironmentVariable {
  'dynamicBackendApi' = 'DYNAMIC_BACKEND_API',
  'sentryAuthToken' = 'SENTRY_AUTH_TOKEN',
  'siteUrl' = 'NEXT_PUBLIC_SITE_URL',
  'unityApi' = 'NEXT_PUBLIC_UNITY_API',
  'unityIdClient' = 'UNITY_ID_CLIENT',
  'unityIdSecret' = 'UNITY_ID_SECRET',
  'usgApi' = 'USG_API',
  'usgApiUsername' = 'USG_API_USERNAME',
  'usgApiPassword' = 'USG_API_PASSWORD',
  'nextAuthSecret' = 'NEXTAUTH_SECRET',
  'gcpProject' = 'GCP_PROJECT',
  'otelTraceUrl' = 'OTEL_TRACE_URL',
  'cloudTraceExporterEnabled' = 'CLOUD_TRACE_EXPORTER_ENABLED',
  'otelTraceExporterEnabled' = 'OTEL_TRACE_EXPORTER_ENABLED',
  'consoleTraceExporterEnabled' = 'CONSOLE_TRACE_EXPORTER_ENABLED',
  'otelLogLevel' = 'OTEL_LOG_LEVEL',
  'namespace' = 'NAMESPACE',
  'hostname' = 'HOSTNAME',
  'imageTag' = 'IMAGE_TAG',
  'traceSamplingRate' = 'TRACING_SAMPLING_RATE',
  'amplitudeExperimentsServerDeploymentKey' = 'AMPLITUDE_EXPERIMENTS_SERVER_DEPLOYMENT_KEY',
  'contentfulBaseUrl' = 'CONTENTFUL_BASE_URL',
  'contentfulGraphQlUrl' = 'CONTENTFUL_GRAPHQL_URL',
  'contentfulSpaceId' = 'CONTENTFUL_SPACE_ID',
  'contentfulDeliveryToken' = 'CONTENTFUL_DELIVERY_TOKEN',
  'contentfulPreviewToken' = 'CONTENTFUL_PREVIEW_TOKEN',
  'contentfulEnvironment' = 'CONTENTFUL_ENVIRONMENT',
  'nextEnvironment' = 'NEXT_PUBLIC_ENVIRONMENT',
  'coveoStorefrontApiKey' = 'COVEO_STOREFRONT_API_KEY',
  'coveoStorefrontListingApiKey' = 'COVEO_STOREFRONT_LISTING_API_KEY',
  'disableBasicAuthMiddleware' = 'DISABLE_BASIC_AUTH_MIDDLEWARE',
  'disableCaching' = 'DISABLE_CACHING',
}

/**
 * A helper function for using environment variables.
 * Provides type safety and a single source of truth for environment variables.
 */
export function getEnvVar(variable: EnvironmentVariable): string {
  return process.env[variable] as string;
}
