export enum Flags {
  COVEO_SEARCH = 'as-coveo-search',
  HOMEPAGE_FEATURE = 'asset-store-new-homepage',
  SHOW_PURCHASED_STATE = 'asset-store-show-product-purchased-state',
  NEW_PDP = 'as-new-pdp',
  NEW_LANDING_PAGES = 'as-new-landing-pages',
  COVEO_EXPERIMENT_PIPELINE = 'as-coveo-experiment-pipeline',
  NON_CURATED_PLP_WITH_ML = 'as-coveo-non-curated-plp-with-ml',
  NON_CURATED_PLP = 'as-non-curated-plp',
}

export const FLAG_CONTROL = 'control';
export const FLAG_TREATMENT = 'treatment';

// keys specific for NON_CURATED_PLP_WITH_ML
export const FLAG_TREATMENT_NO_ML = 'treatment-no-ml';
export const FLAG_TREATMENT_ML = 'treatment-ml';

export const FEATURE_FLAG_ON = 'on';
export const FEATURE_FLAG_OFF = 'off';
