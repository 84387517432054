'use client';

import { MicrocopyFragment } from '@graphql/generated-contentful/graphql';
import { PropsWithChildren, createContext, useContext } from 'react';

export const MicrocopyContext = createContext<
  { [key: string]: MicrocopyFragment[] } | undefined
>(undefined);

export const MicrocopyProvider = ({
  children,
  value,
}: PropsWithChildren<{
  value: { [key: string]: MicrocopyFragment[] } | undefined;
}>) => {
  return (
    <MicrocopyContext.Provider value={value}>
      {children}
    </MicrocopyContext.Provider>
  );
};

export const useMicrocopy = <T,>() => {
  const values = useContext(MicrocopyContext);
  return values as T;
};
