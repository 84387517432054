import { ExperimentUser } from '@amplitude/experiment-js-client';
import { COOKIE_EXPIRY_SECONDS } from '@lib/constants/cookies';
import { isDevelopment } from '@lib/env';
import { Cookie, Cookies } from '@lib/types/Cookies';
import { FlagVariant, FlagVariants } from '..';

/**
 * Gets the Amplitude cookie name based on the API key
 *
 * Implementation taken from AmplitudeCookie.cookieName(). Replicated here so we don't have to import the entire node-package, and can use it in the client and middleware.
 * https://github.com/amplitude/experiment-node-server/blob/5507e193af05d7f95b2dc9b24757562ff8e5eba0/packages/node/src/cookie.ts#L15
 */
export const getAmplitudeCookieName = () => {
  const API_KEY_SUBSTRING_LENGTH = 10;
  return (
    'AMP_' +
    process.env.NEXT_PUBLIC_AMPLITUDE_ANALYTICS_API_KEY!.substring(
      0,
      API_KEY_SUBSTRING_LENGTH
    )
  );
};

/**
 * Implementation taken from AmplitudeCookie.parse(). Replicated here so we don't have to import the entire node-package, and can use it in the client and middleware.
 * https://github.com/amplitude/experiment-node-server/blob/5507e193af05d7f95b2dc9b24757562ff8e5eba0/packages/node/src/cookie.ts#L35
 */
export const parseAmplitudeCookie = (
  amplitudeCookie: string
): ExperimentUser => {
  try {
    const decoding = Buffer.from(amplitudeCookie, 'base64').toString('utf-8');
    const userSession = JSON.parse(decodeURIComponent(decoding));
    return {
      device_id: userSession.deviceId,
      user_id: userSession.userId,
    };
  } catch (e) {
    console.error(`Error parsing the Amplitude cookie: `, e);
    return {};
  }
};

export const getCookieValue = (
  cookies: Cookies,
  cookieName: string
): string | undefined => {
  if (cookies.get && typeof cookies.get !== 'string') {
    return cookies.get(cookieName)?.value;
  }

  return cookies[cookieName];
};

/**
 * Gets the Amplitude Device Id on the server-side from cookies
 */
export const getAmplitudeIdsFromCookies = (
  cookies: Cookies
): { device_id?: string; user_id?: string } | undefined => {
  const ampCookieName = getAmplitudeCookieName();
  const ampCookieValue = getCookieValue(cookies, ampCookieName);

  return ampCookieValue ? parseAmplitudeCookie(ampCookieValue) : undefined;
};

/**
 * Generate a new Amplitude cookie with the given device id.
 * Consumer is responsible for setting the cookie in it's response.
 */
export const generateNewAmplitudeCookie = (deviceId: string): Cookie => {
  const ampCookieName = getAmplitudeCookieName();
  /**
   * Cookie generation code taken from AmplitudeCookie.generate(). Replicated here so we don't have to import the entire node-package, and can use it in the client and middleware.
   * https://github.com/amplitude/experiment-node-server/blob/5507e193af05d7f95b2dc9b24757562ff8e5eba0/packages/node/src/cookie.ts#L74
   */
  const userSessionHash = {
    deviceId,
  };
  const json_data = JSON.stringify(userSessionHash);
  const encoded_json = encodeURIComponent(json_data);
  const base64Encoded = Buffer.from(encoded_json).toString('base64');

  const ampCookieValue = base64Encoded;

  return {
    name: ampCookieName,
    value: ampCookieValue,
    path: '/',
    maxAge: COOKIE_EXPIRY_SECONDS,
    sameSite: 'lax',
    domain: isDevelopment() ? undefined : '.unity.com',
  };
};

/**
 * Parse the experiment override cookie to match the expected format.
 * Amplitude REST API returns the experiment value only in the key field, but server and client sdk expects the value in the value field.
 */
export const parseExperimentOverrideCookie = (cookie: string): FlagVariants => {
  const variants = {};

  try {
    Object.entries(JSON.parse(cookie)).forEach(
      (flag: [string, FlagVariant]) => {
        const [key, flagValue] = flag;
        variants[key] = {
          ...flagValue,
          key: flagValue.key,
          value: flagValue.key,
        };
      }
    );
  } catch (e) {
    console.error('Error parsing the experiment override cookie: ', e);
  }

  return variants;
};
