'use client';

import { OPT_OUT_COOKIE_NAME } from '@lib/constants/oneTrust';
import {
  getFunctionalConsentStatus,
  getPerformanceConsentStatus,
  getTargetingConsentStatus,
} from '@lib/cookieConsent/cookieConsent';
import Cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { waitForElementToExist } from 'utils';

const ONETRUST_SDK_WRAPPER = '#onetrust-consent-sdk';

export const useCookieSettings = () => {
  const [consentCategoriesState, setConsentCategoriesState] = useState({
    functional: false,
    targeting: false,
    performance: false,
  });

  const updateStateFromCookie = () => {
    const optanonConsent = Cookie.get(OPT_OUT_COOKIE_NAME);
    const cookieConsent = decodeURIComponent(optanonConsent || '');

    setConsentCategoriesState({
      functional: getFunctionalConsentStatus(cookieConsent),
      targeting: getTargetingConsentStatus(cookieConsent),
      performance: getPerformanceConsentStatus(cookieConsent),
    });
  };

  useEffect(() => {
    waitForElementToExist(ONETRUST_SDK_WRAPPER).then(() => {
      const oneTrust = (window as any).OneTrust;
      if (oneTrust) {
        oneTrust.OnConsentChanged(() => {
          updateStateFromCookie();
        });
      }
    });

    updateStateFromCookie();
  }, []);

  return {
    functionalCategoryEnabled: consentCategoriesState.functional,
    targetingCategoryEnabled: consentCategoriesState.targeting,
    performanceCategoryEnabled: consentCategoriesState.performance,
  };
};
