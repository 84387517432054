'use client';

import { createContext, useContext } from 'react';

export type RouterContextType = {
  locale: string;
  changeLocale: (newLocale: string) => void;
  push: ({
    url,
    as,
    options,
  }: {
    url: string;
    as?: string;
    options?: { scroll?: boolean; shallow?: boolean; locale?: string };
  }) => void;
  reload: () => void;
};

export const RouterContext = createContext<RouterContextType | undefined>(
  undefined
);

/**
 * Custom router to bridge the gap between the router the pages router uses (next/router) and the router the app directory uses (next/navigation)
 */
export const useRouter = () => {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error('useRouter must be used within a RouterContext');
  }
  return context;
};
