'use client';
// use this wrapper on any page that needs to use the Coveo Atomic library

import { useMicrocopy } from '@contexts/MicrocopyContext';
import { useRouter } from '@contexts/Router/RouterContext';
import { AtomicContext } from '@contexts/coveo/AtomicContext';
import { AtomicSearchInterface } from '@coveo/atomic-react';
import { MicrocopyFragment } from '@graphql/generated-contentful/graphql';
import { microcopySetToResourceBundles } from '@lib/coveo/microcopySetToResourceBundles';
import { useContext } from 'react';

// pass in any fields we want to use for facets or displayed in the results
const fields = [
  'ec_sale',
  'ec_name',
  'ec_thumbnails',
  'ec_price_filter',
  'ec_rating',
  'ec_rating_count',
  'publisher_name',
  'publisher_id',
  'ec_product_id',
  'ec_tags',
  'min_unity_version',
  'ec_flash_teaser_starts_at',
];

type AtomicSearchInterfaceWrapperProps = React.PropsWithChildren<{
  id: string;
  shouldExecuteFirstSearch: boolean;
  reflectStateInUrl: boolean;
  context: AtomicContext;
}>;

/*
 * Use this anywhere we need to use Atomic components.
 * Although its called "AtomicSearchInterface" - this is still the same interface used to display "Listings"
 *
 * Only Atomic components should be wrapped in this as it will only render after the engine is initialized, on the client,
 * so nothing will be rendered server-side. We don't want to wrap components in this willy nilly and prevent them from being server rendered where they could be.
 */
export function AtomicSearchInterfaceWrapper({
  id,
  shouldExecuteFirstSearch,
  reflectStateInUrl,
  context,
  children,
}: AtomicSearchInterfaceWrapperProps) {
  const { searchEngine } = useContext(context);
  const { locale } = useRouter();
  const allMicrocopy = useMicrocopy<{
    searchValuesMicrocopy: MicrocopyFragment[];
  }>();
  const searchValuesMicrocopy = allMicrocopy
    ? allMicrocopy.searchValuesMicrocopy
    : [];

  const transformedMicrocopy = searchValuesMicrocopy
    ? microcopySetToResourceBundles(searchValuesMicrocopy)
    : {};

  // only render any Atomic components after the engine is initialized
  if (!searchEngine) {
    return null;
  }

  return (
    <AtomicSearchInterface
      // @ts-ignore
      id={id}
      enableRelevanceInspector={false}
      engine={searchEngine}
      reflectStateInUrl={reflectStateInUrl}
      language={locale}
      fieldsToInclude={fields}
      languageAssetsPath="/api/i18n/search"
      localization={(i18n) => {
        Object.keys(transformedMicrocopy).forEach((resourceBundleName) => {
          i18n.addResourceBundle(
            locale as string,
            `caption-${resourceBundleName}`,
            transformedMicrocopy[resourceBundleName]
          );
        });
      }}
      onReady={(executeFirstSearch) => {
        if (shouldExecuteFirstSearch) {
          return executeFirstSearch();
        }
        return Promise.resolve();
      }}
    >
      {children}
    </AtomicSearchInterface>
  );
}
