import {
  FUNCTIONAL_CATEGORY_ENABLED,
  PERFORMANCE_CATEGORY_ENABLED,
  TARGETING_CATEGORY_ENABLED,
} from '@lib/constants/oneTrust';

const getParsedCookie = (cookieValue: string) => {
  const parsed = new URLSearchParams(cookieValue);
  return parsed.get('groups') || '';
};

export const getPerformanceConsentStatus = (cookieValue = '') => {
  const cookie = getParsedCookie(cookieValue);
  return cookie.includes(PERFORMANCE_CATEGORY_ENABLED);
};

export const getFunctionalConsentStatus = (cookieValue = '') => {
  const cookie = getParsedCookie(cookieValue);
  return cookie.includes(FUNCTIONAL_CATEGORY_ENABLED);
};

export const getTargetingConsentStatus = (cookieValue = '') => {
  const cookie = getParsedCookie(cookieValue);
  return cookie.includes(TARGETING_CATEGORY_ENABLED);
};
