'use client';

import {
  Experiment,
  ExperimentClient,
  ExperimentUser,
} from '@amplitude/experiment-js-client';
import { AmplitudeAnalyticsClient } from '@lib/analytics/amplitude';
import { EXPERIMENTS_OVERRIDE_COOKIE } from '@lib/constants/cookies';
import { useCookieSettings } from '@lib/hooks/useCookieSettings';
import Cookie from 'js-cookie';
import { useSession } from 'next-auth/react';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FlagVariants } from '..';
import {
  getAmplitudeCookieName,
  parseAmplitudeCookie,
  parseExperimentOverrideCookie,
} from './amplitudeCookieHelpers';

export const ExperimentContext = createContext<{
  flags: FlagVariants;
  loading: boolean;
}>({ flags: {}, loading: true });

const useInitFlags = (
  userId: string | undefined,
  deviceId: string | undefined
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [flags, setFlags] = useState<FlagVariants>({});
  const [experimentClient, setExperimentClient] =
    useState<ExperimentClient | null>(null);
  const overrideCookie = Cookie.get(EXPERIMENTS_OVERRIDE_COOKIE);

  useEffect(() => {
    const experiment = Experiment.initialize(
      process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENTS_CLIENT_DEPLOYMENT_KEY!
    );

    if (overrideCookie) {
      const clientFlags = parseExperimentOverrideCookie(overrideCookie);
      setFlags(clientFlags);
      setIsLoading(false);
    } else {
      experiment
        .fetch({ user_id: userId, device_id: deviceId })
        .then((client) => {
          setFlags(client.all());
          setIsLoading(false);
        });
    }

    setExperimentClient(experiment);
  }, [userId, deviceId, overrideCookie]);

  return {
    flags,
    loading: isLoading,
    experimentClient,
  };
};

const useExperimentUser = (): ExperimentUser => {
  const session = useSession();
  const amplitudeCookie = Cookie.get(getAmplitudeCookieName());
  const amplitudeCookieIds = amplitudeCookie
    ? parseAmplitudeCookie(amplitudeCookie)
    : undefined;

  return {
    user_id: session.data?.accessToken?.sub ?? amplitudeCookieIds?.user_id,
    device_id: amplitudeCookieIds?.device_id,
  };
};

type FlagsProviderProps = PropsWithChildren<{
  serverSideFlags?: FlagVariants;
}>;

export const FlagsProvider = ({
  serverSideFlags,
  children,
}: FlagsProviderProps) => {
  const flagsContextValue = useMemo(
    () => ({ flags: serverSideFlags || {}, loading: false }),
    [serverSideFlags]
  );
  if (serverSideFlags) {
    return (
      <ExperimentContext.Provider value={flagsContextValue}>
        {children}
      </ExperimentContext.Provider>
    );
  }

  return <ClientSideFlagsProvider>{children}</ClientSideFlagsProvider>;
};

const ClientSideFlagsProvider = ({ children }) => {
  const user = useExperimentUser();
  const experiments = useInitFlags(user.user_id, user.device_id);
  const flagsContextValue = useMemo(
    () => ({
      flags: experiments.flags,
      loading: experiments.loading,
    }),
    [experiments]
  );

  return (
    <ExperimentContext.Provider value={flagsContextValue}>
      {children}
    </ExperimentContext.Provider>
  );
};

export const useFlags = () => {
  return useContext(ExperimentContext);
};

const sendExposureEvent = (
  user: ExperimentUser,
  key: string,
  value: string
) => {
  const analyticsClient = new AmplitudeAnalyticsClient(user);
  analyticsClient.exposureEvent(key, value);
};

export const useExposureEvent = (key: string) => {
  const [hasFiredEvent, setHasFiredEvent] = useState(false);
  const { flags } = useContext(ExperimentContext);
  const { performanceCategoryEnabled } = useCookieSettings();
  const user = useExperimentUser();

  const flag = flags?.[key];
  const value = flag?.value;
  return {
    value,
    payload: flag?.payload,
    exposureEvent: () => {
      if (user && value && performanceCategoryEnabled && !hasFiredEvent) {
        sendExposureEvent(user, key, value);
        setHasFiredEvent(true);
      }
    },
  };
};
