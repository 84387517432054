import { failingFetcher } from '@lib/http/fetch';
import { createContext, useMemo } from 'react';
import useSWR from 'swr';

export const ServerTimeContext = createContext<{
  serverTimeOffsetInMs: number;
}>({
  serverTimeOffsetInMs: 0,
});

/**
 * Returns the time offset between the client and the next.js server
 * Use to ensure sale related times and countdowns are accurate
 */
export const ServerTimeProvider = ({ children }) => {
  const { data } = useSWR<{ serverTime: string }>(
    `/api/server-time`,
    failingFetcher
  );

  const value = useMemo(() => {
    const clientTime = new Date();
    const serverTime = data?.serverTime
      ? new Date(data.serverTime)
      : clientTime;
    const serverTimeOffsetInMs = clientTime.getTime() - serverTime.getTime();

    return { serverTimeOffsetInMs };
  }, [data]);

  return (
    <ServerTimeContext.Provider value={value}>
      {children}
    </ServerTimeContext.Provider>
  );
};
