import { MicrocopyFragment } from '@graphql/generated-contentful/graphql';

type ResourceBundle = {
  [key: string]: string;
};

export type ResourceBundles = {
  [key: string]: ResourceBundle;
};

const GROUP_NAME_INDEX = 1;
const FIELD_NAME_INDEX = 2;

export const microcopySetToResourceBundles = (
  microcopySet: MicrocopyFragment[]
): ResourceBundles => {
  const output: ResourceBundles = {};

  microcopySet.forEach((microcopy) => {
    const groupName = microcopy.key?.split('.')[GROUP_NAME_INDEX];
    const fieldName = microcopy.key?.split('.')[FIELD_NAME_INDEX];
    const fieldValue = microcopy.value;

    if (groupName && fieldName && fieldValue) {
      output[groupName] = {
        ...(output[groupName] || {}),
        [fieldName]: fieldValue,
      };
    }
  });

  return output;
};
